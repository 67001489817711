<template>
  <Navbar />
  <router-view />
  <CookiesCard />
  <AppFooter />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Navbar from '@/components/nav/Navbar';
import AppFooter from '@/components/nav/AppFooter';
import CookiesCard from '@/components/rgpd/CookiesCard';

export default {
  name: 'App',
  components: {
    Navbar,
    AppFooter,
    CookiesCard,
  },
  data() {
    return {
      showLangSwitcher: true,
    };
  },
  created() {
    const user = localStorage.getItem('session_id');
    if (!user) {
      localStorage.setItem('session_id', uuidv4());
    }
  },
};
</script>
