import api from '@/utils/services/api';

export default class CookieService {
  static async setSession(user, cookie) {
    try {
      await api.post('/cookies', {
        id: user,
        initial: cookie,
      });
    } catch (err) {
      console.error(err);
    }
  }

  static async getSession(cookie) {
    try {
      const user = localStorage.getItem('session_id');
      const { data } = await api.get(`/cookies/${user}`);
      if (data) this.updateSession(cookie, data);
      else this.setSession(user, cookie);
    } catch (err) {
      console.error(err);
    }
  }

  static async updateSession(cookie, data) {
    try {
      let history = data.history;

      if (history) history.push(cookie);
      else history = [cookie];

      await api.patch(`/cookies/${data.id}`, {
        history,
      });
    } catch (err) {
      console.error(err);
    }
  }
}
