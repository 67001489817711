<template>
  <div class="card success-card shadow text-center">
    <div class="card-header p-4">
      <h2><slot name="title" /></h2>
    </div>
    <div class="card-body">
      <p class="mb-4"><slot name="text" /></p>
      <p>
        <slot name="button" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard'
};
</script>

<style lang="scss">
.success-card {
  padding-bottom: 1rem;
  .card-header {
    background-color: $lightest-green;
    h2 {
      font-size: 24px;
    }
  }
}
</style>
