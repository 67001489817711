import { tags, metaEn, metaFr, metaNl } from '@/utils/data/meta';

export default class MetaService {
  static getMeta(lang) {
    if (lang === 'nl') return metaNl;
    else if (lang === 'fr') return metaFr;
    else return metaEn;
  }

  static setMeta(lang) {
    const meta = this.getMeta(lang);
    document.title = meta.title;
    tags.forEach((tag) => {
      const element = document.querySelector(tag.selector);
      if (element) {
        element.setAttribute('content', meta[tag.type]);
      }
    });
  }
}
