<template>
  <footer class="container-fluid">
    <div class="row upper-section">
      <div class="col-12 col-lg-4">
        <ul>
          <li class="li-title">{{ $t('nav.brand') }}</li>
          <li>
            <a :href="$t('nav.methodo_link')" target="_blank">{{
              $t('nav.methodo')
            }}</a>
          </li>
          <li>
            <a :href="$t('nav.privacy_link')" target="_blank">{{
              $t('nav.privacy')
            }}</a>
          </li>
          <li>
            <app-support-link>Support</app-support-link>
          </li>
        </ul>
        <slot />
      </div>
      <div class="col-12 col-lg-4">
        <ul>
          <li class="li-title">DATS 24</li>
          <li>
            <a
              :href="$t('nav.dats_link')"
              target="_blank"
              >{{ $t('nav.about') }} DATS 24</a
            >
          </li>
          <li class="li-sub">{{ $t('nav.follow') }}</li>
          <li>
            <div class="li-social">
              <a href="https://www.facebook.com/dats24" target="_blank"
                ><i class="bi bi-facebook"></i
              ></a>
              <a href="https://www.instagram.com/dats24" target="_blank"
                ><i class="bi bi-instagram"></i
              ></a>
              <a href="https://www.linkedin.com/company/dats24/" target="_blank"
                ><i class="bi bi-linkedin"></i
              ></a>
              <a
                href="https://www.youtube.com/channel/UCWJOGI9O7eRb14sQbD2cLRg"
                target="_blank"
                ><i class="bi bi-youtube"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-4">
        <ul>
          <li class="li-title">WeLOOP</li>
          <li>
            <a :href="$t('nav.weloop_link')" target="_blank"
              >{{ $t('nav.about') }} WeLOOP</a
            >
          </li>
          <li class="li-sub">{{ $t('nav.follow') }}</li>
          <li>
            <div class="li-social">
              <a href="https://www.linkedin.com/company/weloop" target="_blank"
                ><i class="bi bi-linkedin"></i
              ></a>
              <a href="https://twitter.com/weloop_" target="_blank"
                ><i class="bi bi-twitter"></i
              ></a>
              <a
                href="https://www.youtube.com/channel/UC1dyvTC2eHhPtmyhVoSNMkQ"
                target="_blank"
                ><i class="bi bi-youtube"></i
              ></a>
              <a href="https://www.weloop.org/fr/news/" target="_blank"
                ><i class="bi bi-newspaper"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 text-center mt-4">
        <p class="p-tva mb-0">
          {{ $t('nav.dats-tva1') }}
          <a href="mailto:info@dats24.be">info@dats24.be</a>
          {{ $t('nav.dats-tva2') }}
        </p>
        <p class="p-tva">
          WeLOOP S.A.R.L. - Rue du Bourg 254, 59130 Lambersart -
          <a href="mailto:info@weloop.org">info@weloop.org</a>
          {{ $t('nav.weloop-tva2') }}
        </p>
      </div>
    </div>
    <div class="row lower-section">
      <div class="col-12 col-lg-4 py-2">
        <img src="@/assets/img/colruytgroup.png" />
      </div>
      <div class="col-12 col-lg-8 py-2 links">
        <ul>
          <li>
            &copy; {{ year }}
            <a href="https://weloop.org" target="_blank">WeLOOP</a> &amp;
            <a
              href="https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/home"
              target="_blank"
            >
              Colruyt Group
            </a>
            |
          </li>
          <li>
            <a :href="$t('nav.cookies_link')" target="_blank">
              {{ $t('nav.cookies') }}
            </a>
            |
          </li>
          <li class="modal-link">
            <cookies-params />
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import CookiesParams from '@/components/rgpd/CookiesParams';

export default {
  name: 'AppFooter',
  components: {
    CookiesParams,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  .upper-section {
    background-color: $lightest-grey;
    color: $dark;
    padding-top: 1rem;
    a {
      color: $dark;
      font-size: 14px;
    }
    .li-title {
      font-family: $accent-font;
      font-weight: 600;
      color: $dark;
      font-size: 16px;
    }
    .li-sub {
      font-family: $accent-font;
      font-weight: 500;
      color: $dark-grey;
      font-size: 16px;
    }
    .li-social {
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
        margin-right: 0.5rem;
        color: $dark;
      }
    }
    .p-tva {
      font-size: 12px;
      a {
        font-size: 12px;
      }
    }
  }
  .lower-section {
    background-color: $dark-bg;
    font-size: 0.8rem;
    color: $light;
    img {
      height: 25px;
      margin-left: 1rem;
    }
    ul {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding-right: 2rem;
      li {
        margin-right: 0.5rem;
        a {
          color: $light;
        }
      }
    }
  }
}

@include bp-down(lg) {
  footer {
    .upper-section {
      text-align: center;
    }
    .lower-section {
      .col-12.col-lg-4 {
        display: flex;
        justify-content: center;
      }
      .links {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
