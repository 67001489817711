<template>
  <language-switcher v-slot="{ links }" active-class="router-link-exact-active">
    <router-link
      class="lang-link"
      :to="link.url"
      v-for="link in links"
      :key="link.langIndex"
      :class="link.activeClass"
      exact-active-class=""
    >
      <span>{{ link.langName }}</span>
    </router-link>
  </language-switcher>
</template>

<script>
export default {
  name: 'LangSwitcher',
};
</script>

<style lang="scss">
.router-language-switcher {
  .lang-link:nth-child(1) {
    display: none;
  }
  .lang-link {
    display: inline-block;
    font-size: 16px;
    padding: 0.5rem 1rem;
    color: $dark-grey;
    background-color: $light;
    transition: all 0.3s ease;
    &:hover,
    &.router-link-exact-active {
      background-color: $light-bg;
      color: $dark;
    }
  }
  .lang-link:nth-child(2) {
    border-radius: 4px 0px 0px 4px;
    border: solid;
    border-width: 1px 0.5px 1px 1px;
    border-color: $light-grey;
    &:hover,
    &.router-link-exact-active {
      border-color: $primary;
    }
  }
  .lang-link:nth-child(3) {
    border-radius: 0px 4px 4px 0px;
    border: solid;
    border-width: 1px 1px 1px 0.5px;
    border-color: $light-grey;
    &:hover,
    &.router-link-exact-active {
      border-color: $primary;
    }
  }
}
</style>
