<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-6 img-col">
        <img
          class="img-fluid bg-img mobile-img"
          src="@/assets/img/homepage.jpg"
          alt="Question Images"
        />
        <img
          class="img-fluid bg-img desktop-img"
          src="@/assets/img/homepage-desk.jpg"
          alt="Question Images"
        />
      </div>
      <div class="col-12 col-xl-6 text-col">
        <div class="text-div">
          <h1 class="mb-4 app-title">{{ $t('nav.brand') }}</h1>
          <p class="mb-4">
            {{ $t('home.intro')
            }}<sup class="a-small" @click="showDisclaimer = true">*</sup>.
          </p>
          <div class="mb-4 mt-4">
            <localized-link :to="{ name: 'Test' }">
              <app-button>{{ $t('home.button') }} </app-button>
            </localized-link>
          </div>
          <p>{{ $t('home.sub') }}</p>
          <p class="p-small">
            {{ $t('home.new.date') }} -
            <a class="a-small" @click="showModal = true">{{
              $t('home.new.link')
            }}</a>
            / <a class="a-small" @click="showDisclaimer = true">Disclaimer</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal" :title="$t('home.new.title')" width="30%">
    <p class="text-bold">{{ $t('home.new.date') }}</p>
    <p>{{ $t('home.new.ul') }}</p>
    <ul class="update-list">
      <li>{{ $t('home.new.li1') }}</li>
      <li>{{ $t('home.new.li2') }}</li>
      <li>{{ $t('home.new.li3') }}</li>
    </ul>
    <p>{{ $t('home.new.privacy') }}</p>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false">Ok</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="showDisclaimer" title="Disclaimer" width="30%">
    <p>{{ $t('home.new.disclaimer') }}</p>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showDisclaimer = false">Ok</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      showModal: false,
      showDisclaimer: false,
    };
  },
};
</script>

<style lang="scss">
.update-list {
  li {
    list-style: disc;
  }
}
.mobile-img {
  display: none;
}

@include bp-down(xl) {
  .text-col {
    padding-bottom: 3rem;
  }
  .mobile-img {
    display: flex;
  }
  .desktop-img {
    display: none;
  }
}

@include bp-down(md) {
  .text-col {
    text-align: center;
    justify-content: center;
    .text-div {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}
</style>
