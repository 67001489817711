<template>
  <img
    :src="require('@/assets/img/logo-desktop.svg')"
    class="logo desktop-logo"
    alt="Dats24"
  />
  <img
    :src="require('@/assets/img/logo-mobile.svg')"
    class="logo mobile-logo"
    alt="Dats24 Logo"
  />
</template>

<script>
export default {
    name: 'BrandLogo',
};
</script>

<style lang="scss">
.logo {
  height: 70px;
}

.desktop-logo {
  display: none;
}

@include bp-up(md) {
  .desktop-logo {
    display: block;
  }
  .mobile-logo {
    display: none;
  }
}
</style>
